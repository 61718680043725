import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Modal,
  Progress,
  Space,
  Title,
  Checkbox,
  Button,
  Text,
} from "@mantine/core";
import { useWindowEvent } from "@mantine/hooks";
import React, { useState, useRef, useEffect } from "react";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import redCarLottieJson from "../../assets/animations/red-car.json";
import ConfigService from "../../services/Config.service";
import closeButtonClass from "./CloseButton.module.css";

function clearYoutubeRedirect(
  youtubeTimeoutRedirectId: React.MutableRefObject<any>
) {
  if (youtubeTimeoutRedirectId.current) {
    clearTimeout(youtubeTimeoutRedirectId.current);
  }
}

const RedirectModal = (props: { opened: boolean; modalHandler: any }) => {
  const [progressCount, setProgressCount] = useState(0);
  var intervalTracker = useRef<any>();
  var youtubeTimeoutRedirectId = useRef<any>();

  let timeInSeconds = ConfigService.timeInSeconds;
  let youtubeRedirect = ConfigService.getYoutubeRedirectURL(window);

  function setSkipCountdownLocalStorage(willSkip: boolean) {
    if (willSkip) {
      localStorage.setItem("skipCountdown", "true");
    } else {
      localStorage.removeItem("skipCountdown");
    }
  }

  function redirectModalClose() {
    props.modalHandler.close();
    clearYoutubeRedirect(youtubeTimeoutRedirectId);
    clearProgressCounter(true);
  }

  function clearProgressCounter(resetProgress?: boolean) {
    if (intervalTracker.current != null) {
      clearInterval(intervalTracker.current);
      if (resetProgress) {
        setProgressCount(0);
      }
    }
  }

  useEffect(() => {
    if (props.opened) {
      const timeInMillSeconds = timeInSeconds * 1000;
      let setIntervalDelay = (timeInMillSeconds - 200) / 100;
      intervalTracker.current = setInterval(() => {
        setProgressCount((prevCount) => prevCount + 1);
      }, setIntervalDelay);
      youtubeTimeoutRedirectId.current = setTimeout(() => {
        window.location.href = ConfigService.getYoutubeRedirectURL(window);
      }, timeInMillSeconds);
      console.log("reddir called");
    }
  }, [props.opened, timeInSeconds]);

  useWindowEvent("pageshow", (event) => {
    if (event.persisted) {
      props.modalHandler.close();
      clearProgressCounter(true);
    }
  });

  return (
    <Modal
      onClose={redirectModalClose}
      withCloseButton={false}
      radius="sm"
      styles={{ body: { padding: "0px" } }}
      opened={props.opened}
      overlayProps={{ opacity: 0.7 }}
      centered
    >
      <Progress color="red" size="sm" radius="xs" value={progressCount} />
      <div style={{ padding: "1rem" }}>
        <Space h="md" />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Lottie
            style={{ height: "100px", width: "100px", transform: "scale(3.5)" }}
            loop
            animationData={redCarLottieJson}
            play
          />
        </div>

        <Space h="sm" />
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <FontAwesomeIcon size="lg" icon={faUpRightFromSquare} />
          <Space w="sm" />
          <Title>Redirecting....</Title>
        </div>
        <Space h="sm" />
        <div style={{ textAlign: "center" }}>
          <Text fz="md">
            You will be redirected to{" "}
            <a href={youtubeRedirect}>
              Youtube <FontAwesomeIcon color="#FF0000" icon={faYoutube} />
            </a>{" "}
            in {timeInSeconds} seconds, please click 'GO TO SITE' when asked to
            enable fullscreen
          </Text>
        </div>
        <Space h="sm" />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Checkbox
            className="skipCountdownCheckbox"
            size="md"
            onChange={(event) =>
              setSkipCountdownLocalStorage(event.currentTarget.checked)
            }
            label={`Skip ${timeInSeconds} second countdown next time`}
          />
        </div>
      </div>
      <Button
        onClick={redirectModalClose}
        fullWidth
        classNames={closeButtonClass}
      >
        Cancel
      </Button>
    </Modal>
  );
};

export default RedirectModal;
