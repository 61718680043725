import maxLogo from "../assets/images/max_logo.jpeg";
import crunchyrollLogo from "../assets/images/crunchyroll_logo.webp";
import redditLogo from "../assets/images/reddit_logo.svg";
import amazonPrimeLogo from "../assets/images/amazonprime_logo.webp";
import plexLogo from "../assets/images/plex_logo.jpeg";
import geforceNowLogo from "../assets/images/geforce_now_logo.webp";
import xboxCloudLogo from "../assets/images/xbox_cloud_logo.png";
import lunaLogo from "../assets/images/luna_logo.png";
import googleLogo from "../assets/images/google_logo.png";
import paramountLogo from "../assets/images/paramount_logo.png";

import AppInfo from "../models/AppInfo";
const AvailableAppsService = {
  getMediaApps(): AppInfo[] {
    return [
      {
        name: "Max",
        url: "https://www.max.com/",
        logoImg: maxLogo,
      },
      {
        name: "Crunchyroll",
        url: "https://www.crunchyroll.com/videos/popular",
        logoImg: crunchyrollLogo,
      },
      {
        name: "Prime Video",
        url: "https://www.primevideo.com",
        logoImg: amazonPrimeLogo,
      },
      {
        name: "Paramount+",
        url: "https://www.paramountplus.com/",
        logoImg: paramountLogo,
      },
      {
        name: "Plex",
        url: "https://app.plex.tv",
        logoImg: plexLogo,
      },
      // {
      //   name: "HiAnime",
      //   url: "https://hianime.to/home",
      //   logoImg: hiAnimeLogo,
      //   backgroundColor: "black",
      // },
    ];
  },

  getGameApps(): AppInfo[] {
    return [
      {
        name: "GeForce NOW",
        url: "https://play.geforcenow.com/",
        logoImg: geforceNowLogo,
      },
      {
        name: "Xbox Cloud",
        url: "https://www.xbox.com/en-us/play",
        logoImg: xboxCloudLogo,
      },
      {
        name: "Luna",
        url: "https://luna.amazon.com/",
        logoImg: lunaLogo,
      },
    ];
  },

  getSiteApps(): AppInfo[] {
    return [
      {
        name: "Reddit",
        url: "https://www.reddit.com/",
        logoImg: redditLogo,
      },
      {
        name: "Google",
        url: "https://www.google.com/",
        logoImg: googleLogo,
        backgroundColor: "white",
      },
    ];
  },
};

export default AvailableAppsService;
