import { useEffect, useLayoutEffect, useState } from "react";
import "./App.css";
import {
  Button,
  Card,
  Group,
  Space,
  Text,
  LoadingOverlay,
  SegmentedControl,
  Image,
  AppShell,
  Center,
} from "@mantine/core";
import TheatreAppCard from "./components/AppCard/AppCard";
import AvailableAppsService from "./services/AvailableApps.service";
import {
  faExpand,
  faTriangleExclamation,
  faTv,
  faGamepad,
  faWindowMaximize,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import AppInfo from "./models/AppInfo";
import gsap from "gsap";
import Cookies from "js-cookie";

import electricCarImg from "./assets/icons/electric-car.png";
import ConfigService from "./services/Config.service";
import RedirectModal from "./components/RedirectModal/RedirectModal";

const fullScreenParam = "isFullscreen";

const App = () => {
  // let redirectOnLoad = Cookies.get('redirectOnLoad');
  // if (redirectOnLoad) {

  //   return (<></>)
  // }
  const [opened, modalHandler] = useDisclosure(false);
  const [fullscreenLoaderOverlayVisible, setFullscreenLoader] = useState(false);

  const lastUsedCategoryCookieName = "lastUsedCategory";
  let lastUsedCategory = Cookies.get(lastUsedCategoryCookieName) ?? "A";

  const [currentCategory, setCategory] = useState(lastUsedCategory);

  //const [editMode, setEditMode] = useState(false);

  const skipCountdown = hasSkipCountdownEnabled();

  const urlParams = new URLSearchParams(window.location.search);
  const isFullScreenParam = urlParams.get(fullScreenParam);

  let fullscreenNotificationId = ConfigService.fullscreenNotificationId;
  let youtubeRedirect = ConfigService.getYoutubeRedirectURL(window);

  useEffect(() => {
    if (!isFullScreenParam) {
      setTimeout(() => {
        notifications.show({
          id: fullscreenNotificationId,
          title: "YOU'RE NOT FULLSCREEN!",
          message: "Press the GO FULLSCREEN button before selecting any apps!",
          autoClose: 6000,
          icon: <FontAwesomeIcon icon={faTriangleExclamation} />,
          color: "yellow",
        });
      }, 500);
    }
  }, [isFullScreenParam, fullscreenNotificationId]);

  useEffect(() => {
    Cookies.set(lastUsedCategoryCookieName, currentCategory, { expires: 1 });
  }, [currentCategory]);

  useLayoutEffect(() => {
    // create our context. This function is invoked immediately and all GSAP animations and ScrollTriggers created during the execution of this function get recorded so we can revert() them later (cleanup)
    let ctx = gsap.context(() => {
      // Our animations can use selector text like ".box"
      // this will only select '.box' elements that are children of the component
      gsap.fromTo(
        ".app-card",
        { opacity: 0, transform: "translatey(10px)" },
        {
          opacity: 1,
          transform: "translatey(0px)",
          duration: ".75",
          ease: "power2",
          stagger: 0.05,
        }
      );
      // or we can use refs
    }); // <- IMPORTANT! Scopes selector text

    return () => ctx.revert(); // cleanup
  }, [currentCategory]);

  // useLayoutEffect(() => {
  //   // create our context. This function is invoked immediately and all GSAP animations and ScrollTriggers created during the execution of this function get recorded so we can revert() them later (cleanup)
  //   let ctx = gsap.context(() => {

  //     // Our animations can use selector text like ".box"
  //     // this will only select '.box' elements that are children of the component
  //     if (editMode) {
  //       let tl = gsap.timeline({ repeat: -1 });
  //       tl.to(".app-card", { rotate: '2deg', duration: '.1' }).to(".app-card", { rotate: '-2deg', duration: '.1' }).to(".app-card", { rotate: '0deg', duration: '.05' });
  //       //gsap.fromTo(".app-card", { rotate: '0deg', repeat: -1 }, { rotate: '80deg', duration: '.75', ease: 'power2', repeat: -1 });
  //     }
  //     else
  //       gsap.to(".app-card", { rotate: '0deg' });      // or we can use refs

  //   }); // <- IMPORTANT! Scopes selector text

  //   return () => ctx.revert(); // cleanup

  // }, [editMode]);

  function redirectToYoutube() {
    notifications.hide(fullscreenNotificationId);
    if (skipCountdown) {
      setFullscreenLoader(true);
      window.location.href = youtubeRedirect;
    } else {
      modalHandler.open();
    }
  }

  function hasSkipCountdownEnabled(): boolean {
    return localStorage.getItem("skipCountdown") ? true : false;
  }
  return (
    <AppShell withBorder={false} padding="lg" header={{ height: "2rem" }}>
      <AppShell.Header style={{ paddingTop: "1rem" }}>
        <SiteBanner />
      </AppShell.Header>
      <AppShell.Main>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <SegmentedControl
            size="xl"
            value={currentCategory}
            onChange={setCategory}
            data={[
              { label: <PillLabel label="Media" icon={faTv} />, value: "A" },
              {
                label: <PillLabel label="Games" icon={faGamepad} />,
                value: "G",
              },
              {
                label: <PillLabel label="Sites" icon={faWindowMaximize} />,
                value: "W",
              },
            ]}
          />
        </div>
        <Space h="sm" />
        <Card
          className="overflow-scroll"
          shadow="lg"
          padding="lg"
          radius="md"
          withBorder
        >
          <AppList currentCategory={currentCategory} />
        </Card>
        <Space h="sm" />
        <Group justify="center">
          <Button
            rightSection={<FontAwesomeIcon icon={faExpand} />}
            size="xl"
            onClick={redirectToYoutube}
            variant="light"
          >
            GO FULLSCREEN
          </Button>
          {/* <Button rightIcon={<FontAwesomeIcon icon={faExpand} />} size='xl' onClick={() => { setEditMode(!editMode) }} variant='light'>Shake</Button> */}
        </Group>
        <RedirectModal
          opened={opened}
          modalHandler={modalHandler}
        ></RedirectModal>
        <LoadingOverlay
          loaderProps={{ size: "xl", color: "white", variant: "bars" }}
          visible={fullscreenLoaderOverlayVisible}
        />
      </AppShell.Main>
      <AppShell.Footer>
        <Center style={{ paddingBottom: "1rem" }}>
          <Text size="sm" fs="italic">
            Developed by Terry Hanoman
          </Text>
        </Center>
      </AppShell.Footer>
    </AppShell>
  );
};

const SiteBanner = () => {
  return (
    <Group gap="xs" align="center" justify="center">
      <Image width={30} height={30} src={electricCarImg} />
      <Text style={{ fontWeight: 500 }}>Car Theater</Text>
    </Group>
  );
};

const AppList = (props: { currentCategory: string }) => {
  let appList: AppInfo[] = [];
  switch (props.currentCategory) {
    case "G":
      appList = AvailableAppsService.getGameApps();
      break;
    case "W":
      appList = AvailableAppsService.getSiteApps();
      break;
    default:
      appList = AvailableAppsService.getMediaApps();
  }
  return (
    <Group align="center" justify="center">
      {appList.map((mApp) => (
        <TheatreAppCard
          key={mApp.name}
          appInfo={mApp}
          className="app-card"
        ></TheatreAppCard>
      ))}
    </Group>
  );
};

const PillLabel = (props: { label: string; icon: any }) => {
  return (
    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
      <FontAwesomeIcon icon={props.icon} />
      <Text style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
        {props.label}
      </Text>
    </div>
  );
};

export default App;
