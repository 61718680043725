const ConfigService = {
  getYoutubeRedirectURL(currentWindow: Window): string {
    let currentSite = currentWindow.location.href;
    let fullScreenParam = "isFullscreen";
    return `https://www.youtube.com/redirect?q=${currentSite}?${fullScreenParam}=true`;
  },
  timeInSeconds: 5,
  fullscreenNotificationId: "fullscreenWarning",
};

export default ConfigService;
