import { FC, useRef, useState } from "react";
import "./AppCard.css";
import { Card, Image, LoadingOverlay } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import AppInfo from "../../models/AppInfo";
import { gsap } from "gsap";
import { Skeleton } from "@mantine/core";
import { useWindowEvent } from "@mantine/hooks";

interface AppCardProps {
  appInfo: AppInfo;
  className: string;
}

const AppCard: FC<AppCardProps> = (props) => {
  const [loading, loaderHandler] = useDisclosure(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const appCardRef = useRef(null);

  useWindowEvent("pageshow", (event) => {
    if (event.persisted) {
      loaderHandler.close();
    }
  });

  return (
    <div ref={appCardRef} className={props.className}>
      <Card
        component="a"
        href={props.appInfo.url}
        style={{
          backgroundColor: "transparent",
          border: "none",
          boxShadow: "none",
        }}
        onClick={() => {
          animateClick(appCardRef);
          loaderHandler.toggle();
        }}
      >
        <Skeleton height={150} width={300} radius="xl" visible={!imageLoaded}>
          <Image
            radius="lg"
            src={props.appInfo.logoImg}
            height={150}
            width={300}
            alt={props.appInfo.name}
            style={{
              backgroundColor: props.appInfo.backgroundColor ?? "transparent",
            }}
            //styles={{ root:  }}
            onLoad={() => setImageLoaded(true)}
          />
        </Skeleton>

        <LoadingOverlay
          loaderProps={{ size: "lg", color: "white", type: "bars", dur: 300 }}
          overlayProps={{ radius: "xl" }}
          visible={loading}
        />
      </Card>
    </div>
  );
};

function animateClick(ref: any) {
  let tl = gsap.timeline();
  tl.to(ref.current, { scale: 0.95, duration: 0.1 }).to(ref.current, {
    scale: 1,
    duration: 0.2,
  });
}

export default AppCard;
